<template>
    <div class="activies">
        <common-search-bar
            ref="searchBar"
            @change="handleSearch"
        ></common-search-bar>
        <div class="list-container">
            <div class="list" v-if="list.length">
                <div class="item" v-for="item in list" :key="item._id">
                    <div class="img-container">
                        <img
                            class="item-img"
                            alt=""
                            v-real-img="item.cover.src"
                        />
                        <Icon
                            @click="goTo(item)"
                            class="video-play-icon"
                            type="logo-youtube"
                            v-if="item.video"
                        />
                    </div>
                    <div class="item-right">
                        <div class="item-title ellipse" @click="goTo(item)">
                            {{ item.title }}
                        </div>
                        <div class="item-content">
                            <span class="item-content-title"
                                >{{ "活动竞赛时间" | language }}：</span
                            >
                            <span class="item-content-time"
                                >{{ item.beginAt }} {{ "到" | language }}
                                {{ item.endAt }}</span
                            >
                        </div>
                        <div class="item-desc row3-ellipse">
                            {{ item.desc }}
                        </div>
                        <div class="btn-group">
                            <Button
                                style="width:150px;"
                                :type="getButton(item).type"
                                :disabled="getButton(item).disabled"
                                @click="goTo(item)"
                                >{{ getButton(item).text | language }}</Button
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div class="no-date" :class="{'show-no-date':isEmpty}" v-else>
                {{ "没有符合筛选条件的数据" | language }}~
            </div>
            <div class="flex"></div>
            <Page
                :total="total"
                :current="pageNum"
                :page-size="pageSize"
                @on-change="handleChange"
                style="margin:40px 0;"
                show-total
            >
                <span class="ivu-page-total" style="font-size:16px;"
                    >{{ "共有" | language }} {{ total }}
                    {{ "条" | language }}</span
                >
            </Page>
        </div>
    </div>
</template>

<script>
import { languageTranslation } from "../../assets/util/language";
import CommonSearchBar from "../../compontents/information/CommonSearchBar.vue";
export default {
    components: {
        CommonSearchBar
    },
    data() {
        return {
            list: [],
            keyword: "",
            tag: "",
            year: "",
            pageSize: 10,
            pageNum: 1,
            total: 0,
            isEmpty:true
        };
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        handleSearch(val) {
            this.isEmpty = false;
            let { keyword, year, tag } = val;
            if (
                keyword == this.keyword &&
                year == this.year &&
                tag == this.tag
            ) {
                return;
            }
            if (
                this.keyword !== keyword ||
                this.year !== year ||
                this.tag !== tag
            ) {
                this.pageNum = 1;
            }
            this.keyword = keyword;
            this.year = year=='all' ? '':year;
            this.tag = tag;
            this.fetchData();
        },
        async fetchData() {
            let res = await this.$http.get(
                `/information/competition?keyword=${this.keyword}&type=${
                    this.tag
                }&year=${this.year}&pageSize=${this.pageSize}&pageNum=${this
                    .pageNum - 1}`
            );
            this.list = res.result;
            this.total = res.count;
            if(res.result.length == 0){
                this.isEmpty = true;
            }
        },
        goTo(item) {
            let btnInfo = this.getButton(item);
            if (btnInfo.disabled) {
                return this.$Message.error(
                    languageTranslation(`竞赛已完成，请关注下次活动！`)
                );
            } else {
                // if (btnInfo.text == "进行中") {
                //   return this.$Message.error(`竞赛报名已截止，请关注下次活动！`);
                // } else {
                this.$router.push(
                    `/base/information/competition-activities/detail/${item._id}`
                );
                // }
            }
        },
        //分页
        handleChange(page) {
            this.pageNum = page;
            this.fetchData();
        },
        getButton(item) {
            let { beginAt, endAt, deadline } = item;

            let dateline = new Date(deadline), //截止时间
                begin = new Date(beginAt), //开始时间
                now = new Date(),
                end = new Date(endAt); //结束时间
            if (now < dateline) {
                return {
                    text: "可报名",
                    type: "primary",
                    disabled: false
                };
            } else {
                if (now > end) {
                    return {
                        text: "已完成",
                        type: "error",
                        disabled: true
                    };
                } else {
                    return {
                        text: "进行中",
                        type: "warning",
                        disabled: false
                    };
                }
            }
        }
    }
};
</script>

<style lang="less" scoped>
.activies {
    .list-container {
        display: flex;
        flex-direction: column;
        min-height: 1000px;

        .list {
            .item {
                padding: 20px 40px;
                margin-top: 40px;
                height: 236px;
                background: rgba(255, 255, 255, 1);
                box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.14);
                display: flex;
                .img-container {
                    width: 222px;
                    border-radius: 2px;
                    overflow: hidden;
                    position: relative;
                    flex-shrink: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .item-img {
                        width: 100%;
                        height: auto;
                        border-radius: 2px;
                        object-fit: contain;
                        transition: all ease 0.3s;
                        &:hover {
                            transform: scale(1.1);
                        }
                    }
                }
                .item-right {
                    margin-left: 20px;
                    flex: 1;
                }
                .item-title {
                    margin-bottom: 10px;
                    text-align: left;
                    height: 22px;
                    font-size: 16px;
                    font-weight: 600;
                    color: rgba(0, 0, 0, 0.85);
                    line-height: 22px;
                }
                .item-content {
                    margin-bottom: 20px;
                    text-align: left;
                    font-size: 14px;
                    font-weight: 500;
                    color: rgba(0, 0, 0, 0.85);
                    line-height: 24px;
                    .item-content-time {
                        color: rgba(247, 181, 0, 1);
                    }
                }
                .item-desc {
                    margin-bottom: 20px;
                    text-align: left;
                    height: 66px;
                    font-size: 16px;
                    font-weight: 400;
                    color: rgba(0, 0, 0, 0.85);
                    line-height: 22px;
                }
                .btn-group {
                    display: flex;
                    justify-content: flex-end;
                }
            }
        }
        .no-date {
            width: 200px;
            height: 100px;
            line-height: 100px;
            margin: 60px auto;
            text-align: center;
            font-size: 30px;
            font-weight: bold;
            visibility:hidden;
            opacity:0;
        }
        .show-no-data{
            visibility:visible;
            opacity:1;
        }
        .flex {
            flex: 1;
        }
    }
}
@media screen and (max-width: 960px) {
    .activies {
        .list-container {
            .list {
                .item {
                    align-items: center;
                    padding: 10px 8vw;
                    flex-direction: column;
                    height: auto;
                    padding: 4vw 8vw;
                    .img-container {
                        width: 100%;
                        margin-bottom: 20px;
                    }
                    .item-right {
                        margin-left: 15px;
                        .item-title {
                            width: 160px;
                        }
                        .item-content {
                            margin-bottom: 10px;
                        }
                        .item-desc {
                            line-height: 1.2;
                            height: auto;
                            margin-bottom: 10px;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}
</style>
